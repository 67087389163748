export enum HTTP_CUSTOMER_PORTAL {
  FETCH_TRIP_LIST = "/customer-portal/trips/",
}
export enum HTTP_AUTH {
  VERIFY_USER = "/verify-user",
  CREATE_PROFILE = "/create-profile",
}

export enum RESPONSE_TYPE {
  ERROR = "error",
  SUCCESS = "success",
}

export enum HTTP_USER {
  COMPANY_INFO = "/get-company-info",
  GET_ORGANIZATIONS = "/get-master-admin",
  GET_USER_ACCESS = "/get-user-access",
  SENT_USER_INVITATION = "/user-invitation",
  GET_INTERNAL_USER_LIST = "/get-user-list",
  REVOKE_USER_INVITATION = "/revoke-invitation",
  GET_USER_FOR_EDIT = "/get-user-for-edit-access",
  EDIT_USER_ACCESS = "/edit-user-access",
  DELETE_USER_ACCESS = "/delete-user",
}

export enum HTTP_TRIP {
  FETCH_UPCOMING_TRIP_LIST = "/trip-list/upcoming",
  FETCH_ALL_TRIP_LIST = "/trip-list/all",
  FETCH_FIXED_TRIP_DETAILS = "/trip-details/fixed",
  FETCH_NORMAL_TRIP_DETAILS = "/trip-details/normal",
  CANCEL_TRIP = "/trip/cancel/{type}",
  UPDATE_POC = "/trip/update_poc/{type}",
}

export enum HTTP_EXTERNAL_CONTACT {
  EXTERNAL_CONTACT = "/external-contacts",
}

export enum HTTP_ORDER {
  FETCH_ORDER_LIST = "/order-list",
  CREATE_ORDER = "/create-order",
  ACCEPT_ORDER = "/order/accept",
  ORDER_COUNT = "/quotation-count",
  CREATE_QUERY = "/create-query",
  REJECT_ORDER = "/order/reject",
  CREATE_MULTIPLE_TRIP_ORDER = "/multi-trip-order",
}
